.phoneInputContainer {
    margin-bottom: 20px;
   
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    
  }
  
  .phoneInputWrapper {
    display: flex;
    align-items: center;
  }
  
  .phoneInput {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .phoneInput img {
   
    height: 70px;
    margin-right: 5px; /* Add some spacing between the input and the flag */
  }
  
  .errorModal {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  

  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 5000;
  }
  
  /* Loader container */
  .loader-container {
    position: fixed; /* Stays in place */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Perfect center */
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background for the loader */
    color: white; /* White text */
    padding: 30px; /* Extra padding for better readability */
    border-radius: 10px; /* Rounded corners */
    z-index: 100; /* Ensure it's on top of the overlay */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Spinner animation */
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light border */
    border-top: 4px solid white; /* White color on the top */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Rotation animation */
    margin-bottom: 15px; /* Space between spinner and text */
  }
  
  /* Spin animation keyframes */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }